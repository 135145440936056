<template>
  <div>
    <Process v-if="item && item.id" :item="item" />
    <Waiting v-else @onJoined="getProcessing" />
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "Index",
  components: {
    Waiting: () => import("@/components/inventory/Waiting"),
    Process: () => import("@/components/inventory/Process"),
  },
  data: () => ({
    isLoading: false,
    item: {},
  }),
  mounted() {
    this.getProcessing();
  },
  methods: {
    getProcessing() {
      httpClient.post("/inventory/v1/employee-processing").then(({ data }) => {
        this.item = { ...data };
      });
    },
  },
};
</script>
